import { LOGOUT_USER, ENV_CHANGE } from "../types/authTypes";
import {FETCH_USERS,FETCH_WAITLIST, UPDATE_USER, FETCH_USER_STATS, UPDATE_ID_VERIFICATION, FETCH_USER} from "../types/userTypes";
   
   const INITIAL_STATE = {
     data: [],
     waitlist: [],
     user_stats: {},
     total: 0,
     total_pages: 0,
   };
  
   
  const updateUserStatus = (initialData, payload) => {
    const updatedData = initialData.map(user => {
        if (user.id === payload.id) {
            return { ...user, blacklisted: payload.status };
        }
        return user;
    });
    return updatedData;
  };

  const updateUserID = (initialData, payload) => {
    const updatedData = initialData.map(user => {
        if (user.id === payload.user_id) {
            return { ...user, identity: payload };
        }
        return user;
    });
    return updatedData;
  };

  const updateUserDetails = (initialData, payload) => {
    const updatedData = initialData.map(user => {
        if (user.id === payload.id) {
            return { ...user, ...payload };
        }
        return user;
    });
    return updatedData;
  };
   
   // eslint-disable-next-line import/no-anonymous-default-export
   export default (state = INITIAL_STATE, action) => {
  
     switch (action.type) {
       case FETCH_USERS:
          return {
              ...state,
              data: action.payload,
              total: action.total,
              total_pages: action.total_pages,
          }
      case FETCH_WAITLIST:
        return{
          ...state,
          waitlist: action.payload,
          total: action.total,
          total_pages: action.total_pages,
        }
      case UPDATE_USER:
        return{
          ...state,
          data: updateUserStatus(state.data, action.payload),
        }
      case FETCH_USER_STATS:
        return{
          ...state,
          data: action.payload,
        }
      case UPDATE_ID_VERIFICATION:
        return{
          ...state,
          data: updateUserID(state.data, action.payload),
        }
      case FETCH_USER:
        return{
          ...state,
          data: updateUserDetails(state.data, action.payload),
        }
      case ENV_CHANGE:
      case LOGOUT_USER:
        return INITIAL_STATE;
      // case BULK_PROMOTE:
      //   return{
      //     ...state,
      //     waitlist: action.payload,
      //   }
      default:
        return state;
     }
   };